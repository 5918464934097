
.App-section {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.App-section-title {
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#html5qr-code-full-region {
    background-color: #adb5bd;
    color: #282c34;
    font-size: 12pt;
}


@media(max-width: 640px) {
    #html5qr-code-full-region {
        width: 300px;
    }
}

@media(min-width: 640px) {
    #html5qr-code-full-region {
        width: 600px;
    }
}
/* styles.css */
.feature-card .card {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 10%; 
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  background: #515970 !important;
  color: #fff;
}


.Result-container {
    min-width: 600px;
    margin: auto;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.Result-header {
    font-size: 16pt;
    margin-bottom: 20px;
}

/* table {
    width: 800px;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
} */

th,
td {
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
}

th {
    text-align: left;
}

thead th {
    background-color: #55608f;
}

tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

tbody td {
    position: relative;
}

tbody td:hover:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -9999px;
    bottom: -9999px;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: -1;
}


#html5-qrcode-button-camera-permission{
    background-color: rgb(6, 92, 29);
    color: #fff;
    padding:10px;
    border:none;
    border-radius: 20px;
}
#my-qr-reader{
    background-color: #fff5b9;
   
    
}
img[alt="Info icon"] {
    display: none;
}
.fixed-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .fixed-button-container .btn {
    flex: 1;
    margin: 0 10px;
  }
  .fixed-nav-buttons {
    position: fixed;
    top: 60px; /* Adjust based on your header height */
    width: 100%;
    background: white;
    z-index: 1000;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
  }
  
  .fixed-nav-buttons .container {
    display: flex;
    justify-content: space-around;
  }
  
  .fixed-nav-buttons button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
  }
  
  .fixed-nav-buttons button:hover {
    text-decoration: underline;
  }
  .text-danger{
    font-size: 12px;
  }
  .custom-select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .custom-select {
    appearance: none; /* Remove default arrow */
    -webkit-appearance: none; /* Remove default arrow in Safari */
    -moz-appearance: none; /* Remove default arrow in Firefox */
    padding-left: 30px; /* Adjust padding for custom arrow */
    width: 100%;
    cursor: pointer;
  }
  
  .custom-select::after {
    content: '\25BC'; /* Custom arrow content */
    position: absolute;
    left: 10px; /* Position of the custom arrow */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Allow clicks to pass through */
    color: #333; /* Arrow color */
    font-size: 12px; /* Adjust the size of the arrow */
  }
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .button-container .btn {
    margin: 0 10px;
  }
  
  .fixed-button-container {
    position: fixed;
    bottom: 10px;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    z-index: 1000;
  }
  
  .fixed-button-container .btn {
    margin: 0 10px;
    font-size: 12px;
  }
  body {
    font-family:'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  