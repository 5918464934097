.body2 {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .app {
    text-align: center;
  }
  
  .card2 {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 40px 20px;
    width: 100%;
    max-width: 400px;
  }
  
  .check-icon {
    margin-bottom: 20px;
  }
  
  h1 {
    color: #025ce2;
    margin: 0 0 10px;
  }
  
  .eligibility {
    font-weight: bold;
    color: #004d40;
    margin: 10px 0;
  }
  
  .primary-button, .secondary-button, .link-button {
    display: block;
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 10px 0;
    margin: 10px 0;
    font-size: 16px;
    cursor: pointer;
  }
  
  .primary-button {
    background-color: #025ce2;
    color: #fff;
  }
  
  .secondary-button {
    background-color: #fff;
    color: #025ce2;
    border: 1px solid #025ce2;
  }
  
  .link-button {
    background-color: transparent;
    color: #025ce2;
    text-decoration: underline;
  }
  
  .footer {
    margin-top: 20px;
    background-color: #025ce2;
    border-radius: 8px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
  }
  
  .footer-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .card-icon, .arrow-icon {
    flex-shrink: 0;
  }
  
  .footer-title {
    font-weight: bold;
    margin: 0;
  }
  
  .footer-subtitle {
    margin: 0;
    font-size: 14px;
  }
  